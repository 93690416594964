import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Produkt } from "../model/calculation/produkt";
import { Apollo } from "apollo-angular";
import { pluck } from "rxjs/operators";
import {
  PaperCertification,
  Paperdefinition,
} from "../model/calculation/paperdefinition";
import {
  CREATE_PAPER,
  FIND_ALL_PAPER_CERTIFICATION,
  FIND_ALL_PAPERS,
  FIND_PAPERS_BY_ID,
  FIND_PAPERS_WITH_PRODUCTID,
  UPDATE_PAPER,
  DELETE_PAPER_PRODUKT,
  MERGE_PAPER_PRODUKT,
  DELETE_PAPER,
} from "../graphql/paperGql";
import { DELETE_PRODUKT } from "../graphql/calculatorGql";

@Injectable({
  providedIn: "root",
})
export class PaperService {
  constructor(private apollo: Apollo) {}

  findAllPapers(): Observable<Paperdefinition[]> {
    return this.apollo
      .query({
        query: FIND_ALL_PAPERS,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findAllPaperDefinition"));
  }

  findPaperById(id: string): Observable<Paperdefinition> {
    return this.apollo
      .query({
        query: FIND_PAPERS_BY_ID,
        fetchPolicy: "no-cache",
        variables: {
          id: id,
        },
      })
      .pipe(pluck("data", "paperdefinition"));
  }

  createPaperdefinition(paper: Paperdefinition): Observable<Paperdefinition> {
    return this.apollo
      .mutate({
        mutation: CREATE_PAPER,
        variables: {
          rollenBogenPapier: paper.rollenBogenPapier,
          name: paper.name,
          stoffklasse: paper.stoffklasse,
          faerbung: paper.faerbung,
          grammatur: paper.grammatur,
          volumen: paper.volumen,
          price: paper.price,
          paperCertificationList: paper.paperCertificationList?.map(
            (a) => a.id
          ),
        },
      })
      .pipe(pluck("data", "createPaperdefinition"));
  }

  updatePaperdefinition(paper: Paperdefinition): Observable<Paperdefinition> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PAPER,
        variables: {
          id: paper.id,
          rollenBogenPapier: paper.rollenBogenPapier,
          name: paper.name,
          stoffklasse: paper.stoffklasse,
          faerbung: paper.faerbung,
          grammatur: paper.grammatur,
          volumen: paper.volumen,
          price: paper.price,
          paperCertificationList: paper.paperCertificationList?.map(
            (a) => a.id
          ),
        },
      })
      .pipe(pluck("data", "updatePaperdefinition"));
  }

  deletePaper(id: string): Observable<any> {
    return this.apollo.mutate({
      mutation: DELETE_PAPER,
      variables: {
        id: id,
      },
    });
  }

  findAllPaperCertifications(): Observable<PaperCertification[]> {
    return this.apollo
      .query({
        query: FIND_ALL_PAPER_CERTIFICATION,
        fetchPolicy: "no-cache",
      })
      .pipe(pluck("data", "findAllPaperCertification"));
  }

  findAllPaperDefinitionByProdukt(id: string): Observable<Paperdefinition[]> {
    return this.apollo
      .query({
        query: FIND_PAPERS_WITH_PRODUCTID,
        fetchPolicy: "no-cache",
        variables: {
          produktId: id,
        },
      })
      .pipe(pluck("data", "findAllPaperDefinitionByProdukt"));
  }

  deletePaperProduktAssociation(
    produktId: string,
    paperId: string
  ): Observable<any> {
    return this.apollo
      .mutate({
        mutation: DELETE_PAPER_PRODUKT,
        variables: {
          produktId: produktId,
          paperdefinitionId: paperId,
        },
      })
      .pipe(pluck("data", "deletePaperProduktAssociation"));
  }

  mergePaperProduktAssociations(
    produktId: string,
    papers: string[]
  ): Observable<Paperdefinition[]> {
    return this.apollo
      .mutate({
        mutation: MERGE_PAPER_PRODUKT,
        variables: {
          produktId: produktId,
          papers: papers,
        },
      })
      .pipe(pluck("data", "mergePaperProduktAssociations"));
  }
}
