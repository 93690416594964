export class OptionPriceInformation {
  von: number;
  bis: number;
  fixKosten: number;
  variableKosten: number;
  fortDruckKosten: number;

  //  both CA and IN
  from: number;
  to: number;

  //  Inserts
  insertPages: number;
  insertConfig: string;
  makeReady: number;
  plates: number;
  run: number;

  //  One-time Prices
  price: number;

  // Surcharge

  surcharge: number;

  //  Carton
  col0: number;
  col1: number;
  col2: number;
  col3: number;
  col4: number;
  col5: number;
  col6: number;
  col7: number;
  col8: number;
  col9: number;
}

export class ColumnFormData {
  name: string = "";
  index: number = -1;

  constructor(name?: string, index?: number) {
    if (name) {
      this.name = name;
    }
    if (index !== undefined || index === 0) {
      this.index = index;
    }
  }
}
