import { OptionGroup } from "./optionGroup";
import { OptionPriceInformation } from "./optionPriceInformation";
import { Edge } from "./edge";
import { OptionHeader } from "./optionHeader";
import { PreislistenKatalog, Produkt } from "./produkt";
import { OptionUnit } from "./optionUnit";

export class Option {
  id: string;

  constructor(id: string, needs: Edge, prohibit: Option[], parent: Option) {
    this.id = id;
    this.needs = needs;
    this.prohibit = prohibit;
    this.parent = parent;
  }

  toJSON(): any {
    return {
      id: this.id,
      needs: this.needs ? this.needs.toJSON() : null,
      prohibit: this.prohibit.map((option) => option.id),
      parent: this.parent ? this.parent.id : null,
    };
  }

  optionName: string;
  optionType: string; //optionType enum
  userInput: number;
  optionPriceInformation: OptionPriceInformation[];
  optionGroup: OptionGroup;
  optionUnit: OptionUnit;
  optionPosition: string;
  preislistenKatalog: PreislistenKatalog;
  sortId: number;
  fixedValue: number;
  needs: Edge;
  prohibit: Option[];
  parent: Option;
  disabled: boolean;
  dependsOn: boolean;
  headerConfig: OptionHeader;
  showUserInput: boolean = false;
  associatedProducts: string[];
  group?: string;
  groupSortId?: string;
  optionGroupSortId?: number;
  optionGroupName?: string;
  needsSomething: boolean;
  prohibitsSomething: boolean;
  groupId?: string;
  //US
  optionTypeDisplayName: string;
  invoiceCategory: string;
  glCode: string;
  quantityType: string;
  cartonColumnNames: string[];
  requiredQuantityType: string;
  optionMappingProperties?: OptionMappingProperties;
  ancillaryCode: string;
  dependentOptions: string[];
  hideInFrontend: boolean;
}

export interface OptionMappingProperties {
  specification: string;
  wildcard: boolean;
}

export class UpdateOptionMappingRequest {
  id: string;
  preislistenKatalogId: string;
  optionMappingProperties: OptionMappingProperties;
}
