import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  DxCheckBoxModule,
  DxFormComponent,
  DxFormModule,
  DxTagBoxModule,
  DxSelectBoxModule,
  DxDataGridModule,
  DevExtremeModule,
} from "devextreme-angular";
import { formatMessage } from "devextreme/localization";
import { CommonModule } from "@angular/common";
import { OptionFormData } from "../../../model/calculation/optionFormData";
import { DxDataGridComponent } from "devextreme-angular";
import {
  PreislistenKatalog,
  Produkt,
} from "../../../model/calculation/produkt";
import notify from "devextreme/ui/notify";
import { P3AuthService } from "../../../services";
import { User } from "../../../model/user/user";
import {
  dropOptionInvoiceCategoryUs,
  dropOptionPositionDe,
  dropOptionPositionEn,
  dropOptionTypesDe,
  dropOptionTypesEn,
  dropOptionTypesUs,
  glCodes,
  quantityType,
  dropRequiredQuantityType,
} from "./optionSelectBoxValues";
import { OptionUnitAdmin } from "../../../model/calculation/optionUnit";
import { environment } from "src/environments/environment";
import { OptionDataGridDeModule } from "../data-grids/option-data-grid-de/option-data-grid-de.component";
import { OptionDataGridCartonsModule } from "../data-grids/option-data-grid-cartons/option-data-grid-cartons.component";
import {
  OptionDataGridInsertsComponent,
  OptionDataGridInsertsModule,
} from "../data-grids/option-data-grid-inserts/option-data-grid-inserts.component";
import {
  OptionDataGridOTPComponent,
  OptionDataGridOTPModule,
} from "../data-grids/option-data-grid-otp/option-data-grid-otp.component";
import { OptionDataGridSurchargeModule } from "../data-grids/option-data-grid-surcharge/option-data-grid-surcharge.component";
import { DxiItemComponent } from "devextreme-angular/ui/nested";
import { Option } from "../../../model/calculation/option";

@Component({
  selector: "app-option-form",
  templateUrl: "./option-form.component.html",
  styleUrls: ["./option-form.component.scss"],
})
export class OptionFormComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;
  @ViewChild("ancillaryCode") ancillaryCodeTextBox: DxiItemComponent;

  formatMessage = formatMessage;

  @Input() optionData: OptionFormData;
  @Input() produkte: Produkt[];
  @Input() optionUnitsAdmin: OptionUnitAdmin[];
  @Input() preisListe: PreislistenKatalog;
  @Input() dependentOptions: Option[];
  @Output() optionDataChange = new EventEmitter<OptionFormData>();

  tmpLocale: string;

  optionTypesDatasource;
  optionPositionDatasource;
  optionGLCodeDatasource;
  optionRequiredQuantityType;
  optionQuantityForDatasource = quantityType;
  optionInvoiceCategoryDatasource = dropOptionInvoiceCategoryUs;
  environmentIsUsa = environment.isUsa;

  dependentOptionsDataSource: Option[];

  isOptionTypeReadOnly: boolean;

  user: User | undefined = {
    id: "",
    identity: {
      sub: "",
      loginName: "",
      firstName: "",
      lastName: "",
      email: "",
      isInternal: false,
      enabled: false,
      locale: "",
      userCreateDate: new Date(),
      userLastModifiedDate: new Date(),
    },
    newsletterStatus: { subscribed: "" },
    role: "",
    worksFor: [],
    notification: false,
  };

  constructor(private authService: P3AuthService) {
    this.translateLocaleValue = this.translateLocaleValue.bind(this);
    this.stayFocused = this.stayFocused.bind(this);
    this.checkOptionUnit = this.checkOptionUnit.bind(this);
    this.checkFixedValue = this.checkFixedValue.bind(this);
    this.checkOptionPosition = this.checkOptionPosition.bind(this);
    this.isReadOnly = this.isReadOnly.bind(this);
  }

  ngOnInit(): void {
    this.loadSelectBoxes();
  }

  translateLocaleValue(data): string {
    return formatMessage(`locale.${data}`);
  }

  resetValues(): void {
    this.form.instance.resetValues();
  }

  isValid(): boolean | undefined {
    return this.form.instance.validate().isValid;
  }

  stayFocused(e): void {
    setTimeout(() => {
      e.component.focus();
    });
  }

  getLocale() {
    const locale = this.user?.identity.locale;
    return locale != null ? locale : navigator.language;
  }

  loadSelectBoxes(): void {
    this.authService.getUser().subscribe(
      (e) => {
        if (e) {
          this.user = e;
        }
        this.tmpLocale = this.getLocale();
        if (this.environmentIsUsa) {
          this.optionTypesDatasource = dropOptionTypesUs;
          this.optionRequiredQuantityType = dropRequiredQuantityType;
        } else {
          if (this.tmpLocale == "de-DE") {
            this.optionTypesDatasource = dropOptionTypesDe;
            this.optionPositionDatasource = dropOptionPositionDe;
          } else {
            this.optionTypesDatasource = dropOptionTypesEn;
            this.optionPositionDatasource = dropOptionPositionEn;
          }
        }
      },
      (e) => {
        notify({
          message: e,
          type: "error",
          displayTime: 1500,
          height: 100,
        });
      }
    );
  }

  isOptionPositionVisible(): boolean {
    return (
      this.optionData.optionType === "MEHRPREIS" ||
      this.optionData.optionType === "STAFFELMEHRPREIS" ||
      this.optionData.optionType === "STUECKMEHRPREIS"
    );
  }

  isOptionUnitVisible(): boolean {
    return (
      this.optionData.optionType === "STAFFELMEHRPREIS" ||
      this.optionData.optionType === "STUECKMEHRPREIS"
    );
  }

  isFixedValueVisible(): boolean {
    return (
      this.optionData.optionType === "AUFSCHLAG_TECHNISCHE_KOSTEN_FESTWERT" ||
      this.optionData.optionType === "AUFSCHLAG_PAPIERKOSTEN_FESTWERT" ||
      this.optionData.optionType ===
        "AUFSCHLAG_STANDARDPRODUKTKOSTEN_FESTWERT" ||
      this.optionData.optionType ===
        "AUFSCHLAG_GESAMT_NUR_TK_INKL_EXTRAS_FESTWERT" ||
      this.optionData.optionType ===
        "AUFSCHLAG_GESAMTPREIS_INKL_EXTRAS_FESTWERT"
    );
  }

  isOptionPriceInformationGridVisible(): boolean {
    return (
      this.optionData.optionType === "MEHRPREIS" ||
      this.optionData.optionType === "STAFFELMEHRPREIS" ||
      this.optionData.optionType === "STUECKMEHRPREIS"
    );
  }

  checkOptionPosition(e): boolean {
    return !(this.isOptionPositionVisible() && (!e.value || e.value === ""));
  }

  checkOptionUnit(e): boolean {
    return !(this.isOptionUnitVisible() && (!e.value || e.value === ""));
  }

  checkFixedValue(e): boolean {
    return !(this.isFixedValueVisible() && (!e.value || e.value === ""));
  }

  isOptionTypeCartonsSelected(): boolean {
    return this.optionData.optionType === "CARTONS";
  }
  isOptionInsertsGridVisible(): boolean {
    return this.optionData.optionType === "INSERTS";
  }
  isOptionOneTimePriceSelected(): boolean {
    return (
      this.optionData.optionType === "ONE_TIME_PRICES" ||
      this.optionData.optionType === "ONE_TIME_PRICES_1000"
    );
  }

  isOptionSurchargeAndSubSurchargeGridVisible(): boolean {
    return (
      this.optionData.optionType === "SURCHARGE" ||
      this.optionData.optionType === "SUB_SURCHARGE"
    );
  }

  onFormFieldDataChange() {
    this.isReadOnly();
    this.setOptionGLCodeDatasource();
  }

  setOptionGLCodeDatasource() {
    if (glCodes.find((e) => e.priceList == this.preisListe.name)) {
      this.optionGLCodeDatasource = glCodes.find(
        (e) => e.priceList == this.preisListe.name
      )?.codes;
    } else {
      this.optionGLCodeDatasource = glCodes.find(
        (e) => e.priceList == "DEFAULT"
      )?.codes;
    }
  }

  isReadOnly() {
    if (this.environmentIsUsa)
      this.isOptionTypeReadOnly = !!this.form.instance.option("formData").id;
    console.log(this.optionData);
  }
}

@NgModule({
  imports: [
    DxFormModule,
    CommonModule,
    DxCheckBoxModule,
    DxTagBoxModule,
    DxSelectBoxModule,
    DxDataGridModule,
    DevExtremeModule,
    OptionDataGridCartonsModule,
    OptionDataGridDeModule,
    OptionDataGridOTPModule,
    OptionDataGridInsertsModule,
    OptionDataGridSurchargeModule,
  ],
  declarations: [OptionFormComponent],
  exports: [OptionFormComponent],
})
export class OptionFormModule {}
