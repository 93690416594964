import { gql } from "apollo-angular";

export const GET_PAPERDEFINITIONS = gql`
  query findAllPaperDefinition {
    findAllPaperDefinition {
      lastModifiedDate
      createdDate
      id
      name
      grammatur
      price
      __typename
    }
  }
`;

export const GET_PRODUKTE = gql`
  query findAllProdukt {
    findAllProdukt {
      id
      name
      produktVarianten {
        id
        umfang
        maxAuflage
        minAuflage
        hoehe
        breite
      }
      preislistenKatalog {
        id
        name
      }
    }
  }
`;

export const GET_PRODUKTE_BY_PREISLISTEN_KATALOG = gql`
  query findProduktsByPreislistenKatalog($preislistenKatalogId: String!) {
    findProduktsByPreislistenKatalog(
      preislistenKatalogId: $preislistenKatalogId
    ) {
      id
      name
      produktType
      produktVarianten {
        id
        umfang
        maxAuflage
        minAuflage
        hoehe
        breite
      }
      preislistenKatalog {
        id
        name
      }
    }
  }
`;

export const GET_PRODUKT_INFORMATION_BY_PREISLISTEN_KATALOG = gql`
  query findProductInformationByPreislistenKatalog(
    $preislistenKatalogId: String!
  ) {
    findProductInformationByPreislistenKatalog(
      preislistenKatalogId: $preislistenKatalogId
    ) {
      id
      name
      produktType
      preislistenKatalog {
        id
        name
      }
      width
      height
      edition
      reprint
    }
  }
`;

export const GET_PREISLISTENKATALOGE = gql`
  query findAllPreislistenKatalog {
    findAllPreislistenKatalog {
      id
      name
    }
  }
`;

export const GET_PUBLISHER_BY_ID = gql`
  query getPublisher($id: String!) {
    getPublisher(id: $id) {
      id
      disoNumber
      name
      displayName
      publisherGroup {
        id
        disoNumber
        displayName
      }
      standardStudios {
        id
        displayName
      }
      preislistenKatalog {
        id
        name
        produktList {
          id
          name
          paperdefinitionSet {
            lastModifiedDate
            createdDate
            id
            paperId
            rollenBogenPapier
            name
            stoffklasse
            faerbung
            grammatur
            volumen
            price
            paperCertificationList {
              lastModifiedDate
              createdDate
              id
              name
            }
          }
          produktVarianten {
            id
            umfang
            maxAuflage
            minAuflage
            hoehe
            breite
            technischePreisliste {
              paperId
            }
          }
        }
      }
    }
  }
`;

export const GET_PUBLISHER_BY_ID_IMPORT = gql`
  query getPublisher($id: String!) {
    getPublisher(id: $id) {
      id
      disoNumber
      name
      displayName
      publisherGroup {
        id
        disoNumber
        displayName
      }
      standardStudios {
        id
        displayName
      }
      preislistenKatalog {
        id
        name
        produktList {
          id
          name
          paperdefinitionSet {
            lastModifiedDate
            createdDate
            id
            paperId
            rollenBogenPapier
            name
            stoffklasse
            faerbung
            grammatur
            volumen
            price
            paperCertificationList {
              lastModifiedDate
              createdDate
              id
              name
            }
          }
          importEntries {
            id
            pages
            width
            height
            auflage
            paperId
          }
        }
      }
    }
  }
`;
export const GET_PUBLISHER_BY_ID_NO_VARIANTS = gql`
  query getPublisherNoVariants($id: String!) {
    getPublisherNoVariants(id: $id) {
      id
      disoNumber
      name
      displayName
      publisherGroup {
        id
        disoNumber
        displayName
      }
      standardStudios {
        id
        displayName
      }
      preislistenKatalog {
        id
        name
        produktList {
          id
          name
          paperdefinitionSet {
            lastModifiedDate
            createdDate
            id
            paperId
            rollenBogenPapier
            name
            stoffklasse
            faerbung
            grammatur
            volumen
            price
            paperCertificationList {
              lastModifiedDate
              createdDate
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PRODUKT = gql`
  mutation updateProdukt(
    $id: UUID!
    $name: String!
    $produktType: String!
    $circulationLimit: Boolean!
  ) {
    updateProdukt(
      id: $id
      name: $name
      produktType: $produktType
      circulationLimit: $circulationLimit
    ) {
      id
      name
      preislistenKatalog {
        name
      }
      produktType
      circulationLimit
    }
  }
`;

export const UPDATE_PRODUKT_US = gql`
  mutation updateProduktUs(
    $id: UUID!
    $name: String!
    $produktType: String!
    $width: Float!
    $height: Float!
    $edition: String!
    $reprint: Boolean!
    $twoTitles: Boolean!
  ) {
    updateProduktUs(
      id: $id
      name: $name
      produktType: $produktType
      width: $width
      height: $height
      edition: $edition
      reprint: $reprint
      twoTitles: $twoTitles
    ) {
      id
      name
      preislistenKatalog {
        name
      }
      produktType
      circulationLimit
      width
      height
      edition
      reprint
      twoTitles
    }
  }
`;

export const CREATE_PRODUKT = gql`
  mutation createProdukt(
    $name: String!
    $preislistenKatalogId: UUID!
    $produktType: String!
    $circulationLimit: Boolean!
  ) {
    createProdukt(
      name: $name
      preislistenKatalogId: $preislistenKatalogId
      produktType: $produktType
      circulationLimit: $circulationLimit
    ) {
      id
      name
      preislistenKatalog {
        name
      }
      produktType
      circulationLimit
    }
  }
`;

export const CREATE_PRODUKT_US = gql`
  mutation createProduktUs(
    $name: String!
    $preislistenKatalogId: UUID!
    $produktType: String!
    $circulationLimit: Boolean!
    $width: Float!
    $height: Float!
    $edition: String!
    $reprint: Boolean!
    $twoTitles: Boolean!
  ) {
    createProduktUs(
      name: $name
      preislistenKatalogId: $preislistenKatalogId
      produktType: $produktType
      circulationLimit: $circulationLimit
      width: $width
      height: $height
      edition: $edition
      reprint: $reprint
      twoTitles: $twoTitles
    ) {
      id
      name
      preislistenKatalog {
        name
      }
      produktType
      circulationLimit
      width
      height
      edition
      reprint
      twoTitles
    }
  }
`;

export const DELETE_PRODUKT = gql`
  mutation deleteProdukt($id: UUID!) {
    deleteProdukt(id: $id)
  }
`;

export const CREATE_PREISLISTENKATALOG = gql`
  mutation createPreislistenKatalog($name: String!) {
    createPreislistenKatalog(name: $name) {
      id
      name
    }
  }
`;

export const UPDATE_PREISLISTENKATALOG = gql`
  mutation updatePreislistenKatalog($id: UUID!, $name: String!) {
    updatePreislistenKatalog(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const GET_OPTION = gql`
  query getOption($optionId: UUID!) {
    getOption(optionId: $optionId) {
      id
      optionName
      optionGroup {
        id
        groupName
        sortId
      }
      sortId
    }
  }
`;

export const FIND_OPTION_BY_PRODUKT = gql`
  query findOptionByProdukt($produktId: UUID!) {
    findOptionByProdukt(produktId: $produktId) {
      id
      produktOptions {
        id
        needs {
          edgeType
          nodeList {
            id
          }
        }
        prohibit {
          id
        }
        parent {
          id
        }
      }
    }
  }
`;

export const UPDATE_PRODUKT_OPTION = gql`
  mutation updateProduktOption($ruleset: String!) {
    updateProduktOption(ruleset: $ruleset)
  }
`;

export const GET_PRODUKT = gql`
  query getProdukt($id: String!) {
    getProdukt(id: $id) {
      id
      name
      produktType
      circulationLimit
      preislistenKatalog {
        id
      }
      produktVarianten {
        lastModifiedDate
        createdDate
        id
        maxAuflage
        minAuflage
        hoehe
        breite
        umfang
        technischePreisliste {
          lastModifiedDate
          createdDate
          fdContent
          fdCover
          fdTk
          fdTkCover
          fdTotal
          id
          paperId
          paperContentFix
          paperContentVar
          paperCoverFix
          paperCoverVar
          pricePerPiece
          tkContentFix
          tkContentVar
          tkCoverFix
          tkCoverVar
          standardFormat
          rollenBreite
          zylinderUmfang
        }
      }
    }
  }
`;

export const GET_PRODUKT_IMPORT = gql`
  query getProdukt($id: String!) {
    getProdukt(id: $id) {
      id
      name
      produktType
      circulationLimit
      width
      height
      edition
      reprint
      preislistenKatalog {
        id
      }
      importEntries {
        id
        pages
        width
        height
        auflage
        paperId
        paperContentFix
        paperContentVar
        paperCoverFix
        paperCoverVar
        tkContentFix
        tkContentVar
        tkCoverFix
        tkCoverVar
        pricePerPiece
        fdContent
        fdCover
        fdTk
        fdTkCover
        fdTotal
        standardFormat
        rollenBreite
        zylinderUmfang
      }
    }
  }
`;

export const GET_USA_PRODUCT_PRICE_SCALE = gql`
  query getProdukt($id: String!) {
    getProdukt(id: $id) {
      id
      name
      produktType
      width
      height
      edition
      reprint
      twoTitles
      preislistenKatalog {
        id
      }
      priceScaleEntries {
        pages
        width
        height
        quantity
        paperId
        imposeOutputCtp
        proofBook
        plates
        textMr
        textRun
        bindingMr
        bindingRun
        additionals
      }
    }
  }
`;

export const GET_VARIANTS_FOR_PAGES = gql`
  query getProduktVariantsForPages($id: String!, $pages: Int!) {
    getProduktVariantsForPages(id: $id, pages: $pages) {
      id
      pages
      width
      height
      auflage
      paperId
      paperContentFix
      paperContentVar
      paperCoverFix
      paperCoverVar
      tkContentFix
      tkContentVar
      tkCoverFix
      tkCoverVar
      pricePerPiece
      fdContent
      fdCover
      fdTk
      fdTkCover
      fdTotal
      standardFormat
      rollenBreite
      zylinderUmfang
    }
  }
`;

export const GET_PRODUKT_IMPORT_PAGES = gql`
  query getProduktImportPages($id: String!) {
    getProduktImportPages(id: $id) {
      id
      name
      produktType
      circulationLimit
      preislistenKatalog {
        id
      }
      importEntries {
        id
        pages
        auflage
      }
    }
  }
`;

export const GET_PRODUKT_SPEZIFIKATION_DEFAULT_VALUE_BY_PRODUKT_AND_CATEGORY = gql`
  query findProduktSpezifikationDefaultValuesByProduktIdAndMasterCategoryAndCategory(
    $produktId: String!
    $masterCategory: String!
    $category: String!
  ) {
    findProduktSpezifikationDefaultValuesByProduktIdAndMasterCategoryAndCategory(
      produktId: $produktId
      masterCategory: $masterCategory
      category: $category
    ) {
      id
      masterCategory
      category
      value
    }
  }
`;

export const GET_PRODUKT_SPEZIFIKATION_BY_PRODUKT_AND_CATEGORY = gql`
  query findProduktSpezifikationenByProduktIdAndMasterCategoryAndCategory(
    $produktId: String!
    $masterCategory: String!
    $category: String!
  ) {
    findProduktSpezifikationenByProduktIdAndMasterCategoryAndCategory(
      produktId: $produktId
      masterCategory: $masterCategory
      category: $category
    ) {
      id
      masterCategory
      category
      value
    }
  }
`;

export const GET_PRODUKT_SPEZIFIKATION_BY_PRODUKT = gql`
  query getProduktInformationen($produktId: UUID!, $language: String!) {
    getProduktInformationen(produktId: $produktId, language: $language) {
      bezug
      bindeArt
      broschurUmschlag
      deckelPappenDicke
      deckenPraegung
      etiketten
      inhalt
      kapitalBand
      leimArt
      lieferung
      rillungBroschurUmschlag
      rueckenForm
      schutzUmschlag
      sonstigesTeil
      sonstigeWV
      verpackung
      vorNachsatz
      sonstiges
    }
  }
`;

export const FIND_OPTION = gql`
  query findOption($preislistenKatalogId: UUID!) {
    findOption(preislistenKatalogId: $preislistenKatalogId) {
      id
      optionName
      optionType
      optionPosition
      sortId
      fixedValue
      invoiceCategory
      glCode
      quantityType
      cartonColumnNames
      optionPriceInformation {
        von
        bis
        fixKosten
        variableKosten
        fortDruckKosten

        from
        to

        col0
        col1
        col2
        col3
        col4
        col5
        col6
        col7
        col8
        col9

        insertPages
        insertConfig
        makeReady
        plates
        run

        price

        surcharge
      }
      optionUnit {
        id
        unitNameDe
        unitNameEn
      }
      optionGroup {
        id
        groupName
      }
      fixedValue
    }
  }
`;

export const FIND_PRODUKT_OPTIONS = gql`
  query findProduktOptions($produktId: UUID!, $selectedNodeIds: [UUID]!) {
    findProduktOptions(
      produktId: $produktId
      selectedNodeIds: $selectedNodeIds
    ) {
      selectable {
        id
        needs {
          edgeType
          nodeList {
            id
          }
        }
        prohibit {
          id
        }
        parent {
          id
        }
      }
      choices {
        id
        needs {
          edgeType
          nodeList {
            id
          }
        }
        prohibit {
          id
        }
        parent {
          id
        }
      }
      paths {
        id
        needs {
          edgeType
          nodeList {
            id
          }
        }
        prohibit {
          id
        }
        parent {
          id
        }
      }
      options {
        id
        optionName
        optionType
        optionPosition
        sortId
        fixedValue
        invoiceCategory
        glCode
        quantityType
        cartonColumnNames
        optionPriceInformation {
          von
          bis
          fixKosten
          variableKosten
          fortDruckKosten

          from
          to

          col0
          col1
          col2
          col3
          col4
          col5
          col6
          col7
          col8
          col9

          insertPages
          insertConfig
          makeReady
          plates
          run

          price
          surcharge
        }
        optionUnit {
          id
          unitNameDe
          unitNameEn
        }
        optionGroup {
          id
          groupName
          sortId
        }
        fixedValue
      }
    }
  }
`;

export const SAVE_OR_DELETE_PRODUKTSPEZIFIKATION = gql`
  mutation saveOrDeleteProduktSpezifikation(
    $produktSpezifikationen: [ProduktSpezifikationRequest]!
  ) {
    saveOrDeleteProduktSpezifikation(
      produktSpezifikationen: $produktSpezifikationen
    ) {
      masterCategory
      category
    }
  }
`;

export const DELETE_SELECTED_PRODUKTSPEZIFIKATION_BY_PRODUKT = gql`
  mutation deleteSelectedProduktSpezifikationByProdukt(
    $produktId: UUID!
    $masterCategory: String!
    $category: String!
  ) {
    deleteSelectedProduktSpezifikationByProdukt(
      produktId: $produktId
      masterCategory: $masterCategory
      category: $category
    ) {
      masterCategory
      category
    }
  }
`;

export const DELETE_ALL_PRODUKTSPEZIFIKATION_BY_PRODUKT = gql`
  mutation deleteAllProduktSpezifikationByProdukt($produktId: UUID!) {
    deleteAllProduktSpezifikationByProdukt(produktId: $produktId) {
      id
    }
  }
`;

export const GET_OPTIONS_BY_OPTION_GROUP = gql`
  query findOptionByOptionGroup($optionGroupId: UUID!) {
    findOptionByOptionGroup(optionGroupId: $optionGroupId) {
      id
      optionName
      optionType
      optionPosition
      sortId
      fixedValue
      invoiceCategory
      ancillaryCode
      glCode
      requiredQuantityType
      quantityType
      cartonColumnNames
      optionPriceInformation {
        von
        bis
        fixKosten
        variableKosten
        fortDruckKosten

        from
        to

        col0
        col1
        col2
        col3
        col4
        col5
        col6
        col7
        col8
        col9

        insertPages
        insertConfig
        makeReady
        plates
        run

        price
        surcharge
      }
      optionGroup {
        id
        groupName
        sortId
      }
      optionUnit {
        id
      }
      associatedProducts
      dependentOptions
      hideInFrontend
    }
  }
`;

export const GET_OPTION_GROUPS_BY_PREISLISTEN_KATALOG = gql`
  query findOptionGroup($preislistenKatalogId: UUID!) {
    findOptionGroup(preislistenKatalogId: $preislistenKatalogId) {
      id
      groupName
      sortId
      ancillaryType
    }
  }
`;

export const CREATE_OPTION_GROUP = gql`
  mutation createOptionGroup(
    $groupName: String!
    $preislistenKatalogId: UUID!
    $sortId: Int!
    $ancillaryType: String
  ) {
    createOptionGroup(
      groupName: $groupName
      preislistenKatalogId: $preislistenKatalogId
      sortId: $sortId
      ancillaryType: $ancillaryType
    ) {
      id
      groupName
      sortId
      ancillaryType
    }
  }
`;

export const UPDATE_OPTION_GROUP = gql`
  mutation updateOptionGroup(
    $id: UUID!
    $groupName: String!
    $sortId: Int!
    $ancillaryType: String
  ) {
    updateOptionGroup(
      id: $id
      groupName: $groupName
      sortId: $sortId
      ancillaryType: $ancillaryType
    ) {
      id
      groupName
      sortId
      ancillaryType
    }
  }
`;

export const CREATE_OPTION = gql`
  mutation createOption($optionRequest: OptionRequest) {
    createOption(optionRequest: $optionRequest) {
      id
      optionName
      optionType
      optionPosition
      sortId
      fixedValue
      invoiceCategory
      ancillaryCode
      glCode
      requiredQuantityType
      quantityType
      cartonColumnNames
      optionPriceInformation {
        von
        bis
        fixKosten
        variableKosten
        fortDruckKosten

        from
        to

        col0
        col1
        col2
        col3
        col4
        col5
        col6
        col7
        col8
        col9

        insertPages
        insertConfig
        makeReady
        plates
        run

        price
        surcharge
      }
      optionGroup {
        id
        groupName
        sortId
      }
      optionUnit {
        id
        unitNameDe
        unitNameEn
      }
      associatedProducts
      dependentOptions
      hideInFrontend
    }
  }
`;

export const UPDATE_OPTION = gql`
  mutation updateOption($optionRequest: OptionRequest) {
    updateOption(optionRequest: $optionRequest) {
      id
      optionName
      optionType
      optionPosition
      sortId
      fixedValue
      invoiceCategory
      ancillaryCode
      glCode
      requiredQuantityType
      quantityType
      cartonColumnNames
      optionPriceInformation {
        von
        bis
        fixKosten
        variableKosten
        fortDruckKosten

        from
        to

        col0
        col1
        col2
        col3
        col4
        col5
        col6
        col7
        col8
        col9

        insertPages
        insertConfig
        makeReady
        plates
        run

        price

        surcharge
      }
      optionGroup {
        id
        groupName
        sortId
      }
      optionUnit {
        id
        unitNameDe
        unitNameEn
      }
      associatedProducts
      dependentOptions
      hideInFrontend
    }
  }
`;

export const DELETE_OPTION = gql`
  mutation deleteOption($id: UUID!) {
    deleteOption(id: $id)
  }
`;

export const DELETE_OPTION_GROUP = gql`
  mutation deleteOptionGroup($id: UUID!) {
    deleteOptionGroup(id: $id)
  }
`;

export const GET_ALL_OPTION_UNIT = gql`
  query findAllOptionUnit {
    findAllOptionUnit {
      id
      unitNameDe
      unitNameEn
    }
  }
`;

export const GET_OPTIONS_BY_PREISLISTEN_KATALOG = gql`
  query findOptionMappingByPreislistenKatalog($preislistenKatalogId: UUID!) {
    findOptionMappingByPreislistenKatalog(
      preislistenKatalogId: $preislistenKatalogId
    ) {
      id
      optionName
      optionType
      optionMappingProperties {
        width
        height
        edition
        binding
        specification
        wildcard
      }
    }
  }
`;

export const FIND_ALL_OPTION_MAPPING_PROPERTIES = gql`
  query findAllOptionWithoutMappingProperties {
    findAllOptionWithoutMappingProperties {
      id
      optionName
      optionType
      optionMappingProperties {
        width
        height
        edition
        binding
        specification
        wildcard
      }
    }
  }
`;

export const UPDATE_OPTION_MAPPING_PROPERTIES = gql`
  mutation updateOptionMappingProperties(
    $options: [UpdateOptionMappingRequest]
  ) {
    updateOptionMappingProperties(options: $options) {
      id
      optionName
      optionType
      optionMappingProperties {
        width
        height
        edition
        binding
        specification
        wildcard
      }
    }
  }
`;

export const FIND_ALL_PAGES_IN_PRICE_SCALE_BY_PRODUCT_IS = gql`
  query findAllPagesInPriceScaleByProductId($productId: UUID!) {
    findAllPagesInPriceScaleByProductId(productId: $productId)
  }
`;
