import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxLoadIndicatorModule,
  DxResponsiveBoxModule,
  DxSelectBoxModule,
  DxTabPanelModule,
  DxToastModule,
} from "devextreme-angular";

import {
  OrderGridModule,
  CalculationsGridModule,
  UserProfileModule,
} from "./shared/components";
import { P3AuthGuardService } from "./shared/services";
import { HomeComponent } from "./pages/home/home.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { TasksComponent } from "./pages/tasks/tasks.component";
import { LoginSsoComponent } from "./pages/login-sso/login-sso.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { AgbComponent } from "./pages/agb/agb.component";
import { ImprintComponent } from "./pages/imprint/imprint.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { TermsOfUseComponent } from "./pages/terms-of-use/terms-of-use.component";
import { UploadComponent } from "./pages/upload/upload.component";
import { OrderComponent } from "./pages/order/order.component";
import { UserManagementComponent } from "./pages/user-management/user-management.component";
import { ProduktManagementComponent } from "./pages/produkt-management/produkt-management.component";
import { PaperCostsManagementComponent } from "./pages/papercosts-management/papercosts-management.component";
import { ProduktComponent } from "./pages/produkt/produkt.component";
import { OptionManagementComponent } from "./pages/option-management/option-management.component";
import { NewsletterComponent } from "./pages/newsletter/newsletter.component";
import { UserSuggestionComponent } from "./pages/user-suggestion/user-suggestion.component";
import { OrganizationManagementComponent } from "./pages/organization-management/organization-management.component";
import { CalculatorComponent } from "./pages/calculator/calculator.component";
import { CalculationsComponent } from "./pages/calculations/calculations.component";
import {
  ACCOUNT_MANAGER,
  ADMIN,
  ADMIN_US,
  EXTERNAL_USER_ROLES,
  INTERNAL_USER_ROLES,
  MANUFACTURER,
  SALES_MANAGER,
  USA_ROLES,
} from "./shared/model/roles";
import { PrintingTemplateComponent } from "./pages/printing-template/printing-template.component";
import { NotAuthorizedComponent } from "./pages/not-authorized/not-authorized.component";
import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { ExternalFileUploadComponent } from "./pages/external-file-upload/external-file-upload.component";
import { HomeCardModule } from "./shared/components/home-card/home-card.component";
import { CommonModule } from "@angular/common";
import { SimpleOrderGridModule } from "./pages/home/simple-order-grid/simple-order-grid.component";
import { UploadGridWithOrdersModule } from "./pages/home/upload-grid-with-orders/upload-grid-with-orders.component";
import { NewsElementPopupModule } from "./shared/components/news-feed/news-element-popup/news-element-popup.component";
import { NewsElementKachelModule } from "./shared/components/news-feed/news-element-tile/news-element-tile.component";
import { ConfirmationDialogModule } from "./shared/components/confirmation-dialog/confirmation-dialog.component";
import { PapierManagementComponent } from "./pages/papier-management/papier-management.component";
import { CalculationGridModule } from "./pages/home/calculation-grid/calculation-grid.component";
import { CalculationsArchivedComponent } from "./pages/calculations-archived/calculations-archived.component";
import { CalculationsArchivedGridModule } from "./shared/components/calculations-archived-grid/calculations-archived-grid.component";
import { OptionMappingComponent } from "./pages/option-mapping/option-mapping.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [
        ...INTERNAL_USER_ROLES,
        ...EXTERNAL_USER_ROLES,
        ...USA_ROLES,
      ],
    },
  },
  {
    path: "auftraege",
    component: TasksComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
    },
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [
        ...INTERNAL_USER_ROLES,
        ...EXTERNAL_USER_ROLES,
        ...USA_ROLES,
      ],
    },
  },
  {
    path: "login-sso",
    component: LoginSsoComponent,
    canActivate: [],
  },
  {
    path: "nutzungsbedingungen",
    component: TermsOfUseComponent,
  },
  {
    path: "agb",
    component: AgbComponent,
  },
  {
    path: "impressum",
    component: ImprintComponent,
  },
  {
    path: "datenschutz",
    component: PrivacyComponent,
  },
  {
    path: "upload/:id",
    component: UploadComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
    },
  },
  {
    path: "orderdetails/:id",
    component: OrderComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
    },
  },
  {
    path: "produkt",
    component: ProduktManagementComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [ADMIN, SALES_MANAGER, ADMIN_US],
    },
  },
  {
    path: "papercosts",
    component: PaperCostsManagementComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [ADMIN, SALES_MANAGER],
    },
  },
  {
    path: "produktdetails/:id",
    component: ProduktComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [ADMIN, SALES_MANAGER, ADMIN_US],
    },
  },
  {
    path: "option",
    component: OptionManagementComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [ADMIN, SALES_MANAGER, ADMIN_US],
    },
  },
  // currently only in us
  {
    path: "option-mapping",
    component: OptionMappingComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [ADMIN_US],
    },
  },
  {
    path: "user",
    component: UserManagementComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [ADMIN, SALES_MANAGER, ADMIN_US],
    },
  },
  {
    path: "usersuggestions",
    component: UserSuggestionComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [ADMIN, SALES_MANAGER],
    },
  },
  {
    path: "organizations",
    component: OrganizationManagementComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [ADMIN, SALES_MANAGER, ADMIN_US],
    },
  },
  {
    path: "papers",
    component: PapierManagementComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [ADMIN, SALES_MANAGER, ADMIN_US],
    },
  },
  {
    path: "newsletter",
    component: NewsletterComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [ADMIN],
    },
  },
  {
    path: "kalkulationen",
    component: CalculatorComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [
        ...INTERNAL_USER_ROLES,
        ...EXTERNAL_USER_ROLES,
        ...USA_ROLES,
      ],
    },
  },
  /*
  {
    path: "archived-calculations",
    component: CalculationsArchivedComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [
        ...INTERNAL_USER_ROLES,
        ...EXTERNAL_USER_ROLES,
        ...USA_ROLES,
      ],
    },
  },

   */
  {
    path: "kalkulationen/:id",
    component: CalculatorComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [
        ...INTERNAL_USER_ROLES,
        ...EXTERNAL_USER_ROLES,
        ...USA_ROLES,
      ],
    },
  },
  {
    path: "calculations",
    component: CalculationsComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
    },
  },
  {
    path: "standvorlagen",
    component: PrintingTemplateComponent,
    canActivate: [P3AuthGuardService],
    data: {
      allowedRoles: [...INTERNAL_USER_ROLES, ...EXTERNAL_USER_ROLES],
    },
  },
  {
    path: "notauthorized",
    component: NotAuthorizedComponent,
  },
  {
    path: "welcome",
    component: WelcomeComponent,
    canActivate: [P3AuthGuardService],
  },
  {
    path: "external-file-upload/:identifier",
    component: ExternalFileUploadComponent,
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    OrderGridModule,
    CalculationsGridModule,
    UserProfileModule,
    DxFormModule,
    DxResponsiveBoxModule,
    DxTabPanelModule,
    DxDataGridModule,
    HomeCardModule,
    DxSelectBoxModule,
    CommonModule,
    DxButtonModule,
    DxToastModule,
    DxLoadIndicatorModule,
    SimpleOrderGridModule,
    UploadGridWithOrdersModule,
    NewsElementPopupModule,
    NewsElementKachelModule,
    ConfirmationDialogModule,
    CalculationGridModule,
    CalculationsArchivedGridModule,
  ],
  providers: [P3AuthGuardService],
  exports: [RouterModule],
  declarations: [
    HomeComponent,
    ProfileComponent,
    TasksComponent,
    CalculationsComponent,
    CalculationsArchivedComponent,
  ],
})
export class AppRoutingModule {}
