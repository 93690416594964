import { Component, Input, OnInit } from "@angular/core";
import {
  AncillaryItem,
  AncillaryItemGroup,
} from "../../../../../model/calculation/calculationResult";
import { CalculationTableConfig } from "../../calculator-us-formData";
import { CalculatorService } from "../../../../../services/calculator.service";

@Component({
  selector: "app-ancillary-item",
  templateUrl: "./ancillary-item.component.html",
  styleUrls: ["./ancillary-item.component.scss"],
})
export class AncillaryItemComponent implements OnInit {
  @Input()
  calculationTableConfig: CalculationTableConfig;

  @Input()
  displayedAncillaryItems: any[];

  @Input()
  ancillaryItemName: string;
  @Input()
  index: number;

  totalPrices: number[] = [];

  constructor(public calculatorService: CalculatorService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    for (let i = 0; i < this.displayedAncillaryItems.length; i++) {
      const totalPrice =
        this.displayedAncillaryItems[i]?.[this.index].totalPrice;
      this.totalPrices[i] = totalPrice ?? 0;
    }
    console.log("Total Prices of" + this.ancillaryItemName);
    console.log(this.totalPrices);
  }
}
