<dx-form
  id="optionForm"
  labelMode="floating"
  [formData]="optionData"
  colCount="2"
  [colCountByScreen]="{ xs: 1 }"
  validationGroup="newOptionFormValidation"
  (onFieldDataChanged)="onFormFieldDataChange()"
>
  <dxi-item
    dataField="optionName"
    [label]="{ text: formatMessage('option.name') }"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    #optionType
    name="optionType"
    dataField="optionType"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: optionTypesDatasource,
      valueExpr: 'value',
      displayExpr: 'name',
      readOnly: isOptionTypeReadOnly
    }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('optionType') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    #optionPosition
    name="optionPosition"
    dataField="optionPosition"
    *ngIf="isOptionPositionVisible() && !environmentIsUsa"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: optionPositionDatasource,
      valueExpr: 'value',
      displayExpr: 'name',
      readOnly: isOptionTypeReadOnly
    }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('optionPosition') }}"></dxo-label>
    <dxi-validation-rule
      type="custom"
      [message]="formatMessage('validation.required')"
      [validationCallback]="checkOptionPosition"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    #invoiceCategory
    name="invoiceCategory"
    dataField="invoiceCategory"
    *ngIf="environmentIsUsa"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: optionInvoiceCategoryDatasource,
      valueExpr: 'value',
      displayExpr: 'name'
    }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('invoiceCategory') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    #optionUnit
    name="optionUnitId"
    dataField="optionUnitId"
    *ngIf="isOptionUnitVisible()"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: optionUnitsAdmin,
      valueExpr: 'id',
      displayExpr: 'unitName'
    }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('optionUnit') }}"></dxo-label>
    <dxi-validation-rule
      type="custom"
      [message]="formatMessage('validation.required')"
      [validationCallback]="checkOptionUnit"
    >
    </dxi-validation-rule>
  </dxi-item>

  <dxi-item
    #ancillaryCode
    dataField="ancillaryCode"
    *ngIf="environmentIsUsa"
    [label]="{ text: 'Ancillary Code' }"
    [editorOptions]="{
      stylingMode: 'underlined',
      maxLength: 3,
      readOnly: false
    }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    dataField="sortId"
    [label]="{ text: formatMessage('option.sortId') }"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="pattern"
      pattern="^[0-9]{1,3}$"
      [message]="formatMessage('validation.integerOnly')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    #glCode
    dataField="glCode"
    *ngIf="environmentIsUsa"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: optionGLCodeDatasource,
      valueExpr: 'value',
      displayExpr: 'name',
      label: 'GL Code'
    }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('Gl-Code') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    #glCode
    dataField="requiredQuantityType"
    *ngIf="environmentIsUsa"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: optionRequiredQuantityType,
      valueExpr: 'value',
      displayExpr: 'name',
      label: 'Required Quantity'
    }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('Required Quantity') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    dataField="quantityType"
    *ngIf="environmentIsUsa && isOptionTypeCartonsSelected()"
    [editorOptions]="{
      stylingMode: 'underlined',
      dataSource: optionQuantityForDatasource,
      valueExpr: 'value',
      displayExpr: 'name',
      label: 'Quantity for'
    }"
    editorType="dxSelectBox"
  >
    <dxo-label text="{{ formatMessage('Gl-Code') }}"></dxo-label>
    <dxi-validation-rule
      type="required"
      [message]="formatMessage('validation.required')"
    ></dxi-validation-rule>
  </dxi-item>

  <dxi-item
    dataField="fixedValue"
    *ngIf="isFixedValueVisible()"
    [label]="{ text: formatMessage('option.fixedValue') }"
    [editorOptions]="{ stylingMode: 'underlined' }"
  >
    <dxi-validation-rule
      type="pattern"
      pattern="^[-]?[0-9]{1,3}([\.,]\d{1,2})?$"
      [message]="formatMessage('validation.numericOnly')"
    ></dxi-validation-rule>
    <dxi-validation-rule
      type="custom"
      [message]="formatMessage('validation.required')"
      [validationCallback]="checkFixedValue"
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    *ngIf="environmentIsUsa"
    dataField="hideInFrontend"
    [editorOptions]="{
      stylingMode: 'underlined'
    }"
    editorType="dxCheckBox"
  >
  </dxi-item>
</dx-form>

<ng-container
  *ngIf="isOptionPriceInformationGridVisible() && !environmentIsUsa"
>
  <app-option-data-grid-de [optionData]="optionData"> </app-option-data-grid-de>
</ng-container>

<ng-container *ngIf="isOptionTypeCartonsSelected() && environmentIsUsa">
  <app-option-data-grid-cartons [optionData]="optionData">
  </app-option-data-grid-cartons>
</ng-container>

<ng-container *ngIf="isOptionOneTimePriceGridVisible() && environmentIsUsa">
  <app-option-data-grid-otp [optionData]="optionData">
  </app-option-data-grid-otp>
</ng-container>

<ng-container *ngIf="isOptionInsertsGridVisible() && environmentIsUsa">
  <app-option-data-grid-inserts [optionData]="optionData">
  </app-option-data-grid-inserts>
</ng-container>

<ng-container
  *ngIf="isOptionSurchargeAndSubSurchargeGridVisible() && environmentIsUsa"
>
  <app-option-data-grid-surcharge [optionData]="optionData">
  </app-option-data-grid-surcharge>
</ng-container>

<ng-container *ngIf="!environmentIsUsa">
  <dx-drop-down-box
    [(value)]="optionData.associatedProducts"
    valueExpr="id"
    displayExpr="name"
    placeholder="Select a value..."
    [dataSource]="produkte"
  >
    <div *dxTemplate="let data of 'content'">
      <dx-data-grid
        [dataSource]="produkte"
        [columns]="['name', 'produktType']"
        [selection]="{ mode: 'multiple' }"
        [hoverStateEnabled]="true"
        [paging]="{ enabled: true, pageSize: 10 }"
        [filterRow]="{ visible: true }"
        [scrolling]="{ mode: 'virtual' }"
        [height]="345"
        [(selectedRowKeys)]="optionData.associatedProducts"
      >
      </dx-data-grid>
    </div>
  </dx-drop-down-box>
</ng-container>

<ng-container *ngIf="environmentIsUsa">
  <dx-drop-down-box
    style="margin: 5px 0"
    [(value)]="optionData.associatedProducts"
    [label]="formatMessage('optionForm.associatedProducts.dropdown.label')"
    valueExpr="id"
    displayExpr="name"
    [dataSource]="produkte"
  >
    <div *dxTemplate="let data of 'content'">
      <dx-data-grid
        [dataSource]="produkte"
        [columns]="['name', 'produktType']"
        [selection]="{ mode: 'multiple' }"
        [hoverStateEnabled]="true"
        [paging]="{ enabled: true, pageSize: 10 }"
        [filterRow]="{ visible: true }"
        [scrolling]="{ mode: 'virtual' }"
        [height]="345"
        [(selectedRowKeys)]="optionData.associatedProducts"
      >
      </dx-data-grid>
    </div>
  </dx-drop-down-box>

  <dx-drop-down-box
    *ngIf="environmentIsUsa"
    [(value)]="optionData.dependentOptions"
    valueExpr="id"
    displayExpr="name"
    [label]="formatMessage('optionForm.optionDependency.dropdown.label')"
    [dataSource]="dependentOptionsDataSource"
  >
    <div *dxTemplate="let data of 'content'">
      <dx-data-grid
        [dataSource]="dependentOptions"
        [columns]="['optionName', 'optionTypeDisplayName']"
        [selection]="{ mode: 'multiple' }"
        [hoverStateEnabled]="true"
        [paging]="{ enabled: true, pageSize: 10 }"
        [filterRow]="{ visible: true }"
        [scrolling]="{ mode: 'virtual' }"
        [height]="345"
        [(selectedRowKeys)]="optionData.dependentOptions"
      >
      </dx-data-grid>
    </div>
  </dx-drop-down-box>
</ng-container>
