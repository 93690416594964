import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ClipboardModule } from "@angular/cdk/clipboard";

import { ApiModule } from "../../generated/api/api.module";
import { environment } from "../environments/environment";

import { AppComponent } from "./app.component";
import {
  SideNavInnerToolbarModule,
  SideNavOuterToolbarModule,
  SingleCardModule,
} from "./layouts";
import {
  ChangePasswordFormModule,
  CreateAccountFormModule,
  FooterModule,
  LoginFormModule,
  OrderGridModule,
  CalculationsGridModule,
  ResetPasswordFormModule,
  UserProfileModule,
} from "./shared/components";
import {
  AppInfoService,
  AuthService,
  P3AuthService,
  ScreenService,
  MomentService,
} from "./shared/services";
import { UnauthenticatedContentModule } from "./unauthenticated-content";
import { AppRoutingModule } from "./app-routing.module";
import { LoginSsoComponent } from "./pages/login-sso/login-sso.component";
import { PlaceholderComponent } from "./pages/placeholder/placeholder.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import {
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxFileUploaderModule,
  DxFormModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxResponsiveBoxModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSpeedDialActionModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToastModule,
  DxValidationGroupModule,
  DxValidatorModule,
} from "devextreme-angular";
import { AgbComponent } from "./pages/agb/agb.component";
import { ImprintComponent } from "./pages/imprint/imprint.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { TermsOfUseComponent } from "./pages/terms-of-use/terms-of-use.component";
import { AwsCognitoAuthInterceptor } from "./aws-cognito-auth.interceptor";
import { UploadComponent } from "./pages/upload/upload.component";
import { UploadDataModule } from "./shared/components/upload-data/upload-data.component";
import { OrderComponent } from "./pages/order/order.component";
import { OrderDetailsModule } from "./shared/components/order-details/order-details.component";
import { ProduktDetailsModule } from "./shared/components/produkt-details/produkt-details.component";
import { UserManagementComponent } from "./pages/user-management/user-management.component";
import { ProduktManagementComponent } from "./pages/produkt-management/produkt-management.component";
import { PaperCostsManagementComponent } from "./pages/papercosts-management/papercosts-management.component";
import { ProduktComponent } from "./pages/produkt/produkt.component";
import { OptionManagementComponent } from "./pages/option-management/option-management.component";
import { UserFormPopupModule } from "./shared/components/user-management/user-form-popup/user-form-popup.component";
import { ProduktFormPopupModule } from "./shared/components/produkt-management/produkt-form-popup/produkt-form-popup.component";
import { PaperCostsFormPopupModule } from "./shared/components/papercosts-management/papercosts-form-popup/papercosts-form-popup.component";
import { PreislistenKatalogFormPopupModule } from "./shared/components/produkt-management/preislistenKatalog-form-popup/preislistenKatalog-form-popup.component";
import { AuthorizeUsersPopupModule } from "./shared/components/user-management/authorize-users-popup/authorize-users-popup.component";
import { NewsletterComponent } from "./pages/newsletter/newsletter.component";
import { NotAuthorizedComponent } from "./pages/not-authorized/not-authorized.component";
import { AlertModule } from "./shared/components/alert/alert.component";
import { UserSuggestionComponent } from "./pages/user-suggestion/user-suggestion.component";
import { OrganizationUnionTagModule } from "./shared/components/tags/organization-union-tag/organization-union-tag.component";
import { OrganizationManagementComponent } from "./pages/organization-management/organization-management.component";
import { PublisherGroupGridManagement } from "./shared/components/organization-management/publisher-group-grid/publisher-group-grid.component";
import { ActionPopupModule } from "./shared/components/action-popup/action-popup.component";
import { OrganizationFormModule } from "./shared/components/organization-management/organization-form/organization-form.component";
import { OrganizationDataGridModule } from "./shared/components/organization-management/organization-data-grid/organization-data-grid.component";
import { OrganizationTagModule } from "./shared/components/tags/organization-tag/organization-tag.component";
import { ConfirmationDialogModule } from "./shared/components/confirmation-dialog/confirmation-dialog.component";
import { CalculatorComponent } from "./pages/calculator/calculator.component";
import { PrintingTemplateComponent } from "./pages/printing-template/printing-template.component";
import { PrintingTemplateModule } from "./shared/components/printing-template/printing-template.component";
import { ImprimaturComponent } from "./pages/imprimatur/imprimatur.component";
import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { HeaderAndScrollViewModule } from "./layouts/header-and-scroll-view/header-and-scroll-view.component";
import { ExternalFileUploadComponent } from "./pages/external-file-upload/external-file-upload.component";
import { FileUploaderModule } from "./shared/components/upload-data/file-uploader/file-uploader.component";
import { ReturnButtonModule } from "./shared/components/return-button/return-button.component";
import { CalculatorBookDetailsComponent } from "./shared/components/calculator/calculator-de/calculator-book-details/calculator-book-details.component";
import { CalculatorCalculatedPriceComponent } from "./shared/components/calculator/calculator-de/calculator-calculated-price/calculator-calculated-price.component";
import { CalculatorDefaultValuesComponent } from "./shared/components/calculator/calculator-de/calculator-default-values/calculator-default-values.component";
import { PriceTableComponent } from "./shared/components/calculator/calculator-de/calculator-calculated-price/price-table/price-table.component";
import { PapierManagementComponent } from "./pages/papier-management/papier-management.component";
import { PapierDataGridComponent } from "./shared/components/papier-management/papier-data-grid/papier-data-grid.component";
import { PapierFormComponent } from "./shared/components/papier-management/papier-form/papier-form.component";
import { CalculatorPriceOptionsComponent } from "./shared/components/calculator/calculator-de/calculator-price-options/calculator-price-options.component";
import { PriceOptionListComponent } from "./shared/components/calculator/calculator-de/calculator-price-options/price-option-list/price-option-list.component";
import { MehrpreisComponent } from "./shared/components/calculator/calculator-de/calculator-calculated-price/price-table/mehrpreis/mehrpreis.component";
import { OptionGroupFormPopupModule } from "./shared/components/option-management/optionGroup-form-popup/optionGroup-form-popup.component";
import { OptionFormPopupModule } from "./shared/components/option-management/option-form-popup/option-form-popup.component";
import { AufschlagComponent } from "./shared/components/calculator/calculator-de/calculator-calculated-price/price-table/aufschlag/aufschlag.component";
import { PaperHandlingComponent } from "./shared/components/calculator/calculator-de/calculator-calculated-price/price-table/paper-handling/paper-handling.component";
import { EmptyLineComponent } from "./shared/components/calculator/calculator-de/calculator-calculated-price/price-table/empty-line/empty-line.component";
import { ProductFormDeComponent } from "./shared/components/produkt-management/produkt-form/product-form-de/product-form-de.component";
import { ProductFormUsComponent } from "./shared/components/produkt-management/produkt-form/product-form-us/product-form-us.component";
import { OptionDataGridSurchargeComponent } from "./shared/components/option-management/data-grids/option-data-grid-surcharge/option-data-grid-surcharge.component";
import { ColumnFormPopupComponent } from "./shared/components/option-management/data-grids/option-data-grid-cartons/column-form-popup/column-form-popup.component";
import { ColumnFormComponent } from "./shared/components/option-management/data-grids/option-data-grid-cartons/column-form/column-form.component";
import { CalculationsArchivedComponent } from "./pages/calculations-archived/calculations-archived.component";
import { OptionMappingComponent } from "./pages/option-mapping/option-mapping.component";
import { CalculatorUsComponent } from "./shared/components/calculator/calculator-us/calculator-us.component";
import { CalculatorDeComponent } from "./shared/components/calculator/calculator-de/calculator-de.component";
import { AncillaryItemTextPrepComponent } from "./shared/components/calculator/calculator-us/ancillary-item-text-prep/ancillary-item-text-prep.component";
import { AncillaryItemTextPrintComponent } from "./shared/components/calculator/calculator-us/ancillary-item-text-print/ancillary-item-text-print.component";
import { AncillaryItemBindComponent } from "./shared/components/calculator/calculator-us/ancillary-item-bind/ancillary-item-bind.component";
import { AdditionalSpecificationListComponent } from "./shared/components/calculator/calculator-us/additional-specification-list/additional-specification-list.component";
import { AncillaryItemGroupComponent } from "./shared/components/calculator/calculator-us/calculator-us-price-table/ancillary-item-group/ancillary-item-group.component";
import { AncillaryItemComponent } from "./shared/components/calculator/calculator-us/calculator-us-price-table/ancillary-item/ancillary-item.component";
import { CalculatorUsPriceTableComponent } from "./shared/components/calculator/calculator-us/calculator-us-price-table/calculator-us-price-table.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginSsoComponent,
    WelcomeComponent,
    PlaceholderComponent,
    NotFoundComponent,
    NotAuthorizedComponent,
    AgbComponent,
    ImprintComponent,
    PrivacyComponent,
    TermsOfUseComponent,
    OrderComponent,
    UploadComponent,
    UserManagementComponent,
    ProduktManagementComponent,
    PaperCostsManagementComponent,
    ProduktComponent,
    OptionManagementComponent,
    OrganizationManagementComponent,
    UserSuggestionComponent,
    NewsletterComponent,
    CalculatorComponent,
    PrintingTemplateComponent,
    ImprimaturComponent,
    ExternalFileUploadComponent,
    CalculatorBookDetailsComponent,
    CalculatorCalculatedPriceComponent,
    CalculatorDefaultValuesComponent,
    PriceTableComponent,
    PapierManagementComponent,
    PapierDataGridComponent,
    PapierFormComponent,
    CalculatorPriceOptionsComponent,
    PriceOptionListComponent,
    MehrpreisComponent,
    AufschlagComponent,
    PaperHandlingComponent,
    EmptyLineComponent,
    OptionMappingComponent,
    CalculatorUsComponent,
    CalculatorDeComponent,
    AncillaryItemTextPrepComponent,
    AncillaryItemTextPrintComponent,
    AncillaryItemBindComponent,
    AncillaryItemGroupComponent,
    AncillaryItemComponent,
    CalculatorUsPriceTableComponent,
    AdditionalSpecificationListComponent,
  ],
  imports: [
    ApiModule.forRoot({ rootUrl: `${environment.baseUrl}/api` }),
    BrowserModule,
    ClipboardModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    ResetPasswordFormModule,
    CreateAccountFormModule,
    ChangePasswordFormModule,
    LoginFormModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    OrderGridModule,
    CalculationsGridModule,
    UserProfileModule,
    DxButtonModule,
    HttpClientModule,
    UploadDataModule,
    OrderDetailsModule,
    ProduktDetailsModule,
    DxResponsiveBoxModule,
    DxDataGridModule,
    DxSpeedDialActionModule,
    DxPopupModule,
    DxFormModule,
    DxCheckBoxModule,
    DxValidationGroupModule,
    DxScrollViewModule,
    DxToastModule,
    UserFormPopupModule,
    ProduktFormPopupModule,
    PaperCostsFormPopupModule,
    PreislistenKatalogFormPopupModule,
    AuthorizeUsersPopupModule,
    DxTextAreaModule,
    AlertModule,
    OrganizationUnionTagModule,
    PublisherGroupGridManagement,
    ActionPopupModule,
    OrganizationFormModule,
    OrganizationDataGridModule,
    OrganizationTagModule,
    ConfirmationDialogModule,
    DxListModule,
    DxSelectBoxModule,
    PrintingTemplateModule,
    HeaderAndScrollViewModule,
    FileUploaderModule,
    DxLoadIndicatorModule,
    ReturnButtonModule,
    DxFileUploaderModule,
    DxAccordionModule,
    DxLoadPanelModule,
    DxTextBoxModule,
    DxLookupModule,
    OptionGroupFormPopupModule,
    OptionFormPopupModule,
    DxNumberBoxModule,
    DxValidatorModule,
  ],
  providers: [
    AuthService,
    ScreenService,
    AppInfoService,
    P3AuthService,
    MomentService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AwsCognitoAuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
